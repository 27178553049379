export const New = () => {
  return (
    <div className="new">
      <p className="new__title">¿Qué noticias hay?</p>
      <p className="new__description">
        Se agregaron secciones para nuevos usuarios.
      </p>
      <p className="new__description">
        Se ha añadido al mapa la ubicación de la sucursal, cajero automático o
        terminal más cercana.
      </p>
      <p className="new__description">
        Se agregó soporte para Face ID y Touch ID.
      </p>
      <p className="new__description">
        Se solucionó un problema con las notificaciones push que afectaba a
        algunos usuarios de Android 11.
      </p>
      <p className="new__description">
        Se solucionó un problema en el que el texto en Configuración era
        demasiado pequeño para los usuarios de Android.
      </p>
    </div>
  );
};
