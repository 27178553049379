export const Additional = () => {
  return (
    <div className="additional">
      <p className="additional__title">Información complementaria</p>
      <div className="additional__wrapper additional__wrapper--mobile">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Actualizado</p>
            <p className="additional__description">23.01.25</p>
          </div>

          <div className="additional__item">
            <p className="additional__text">Descargas</p>
            <p className="additional__description">77421</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Edad</p>
            <p className="additional__description">18+</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Talla</p>
            <p className="additional__description">4.7MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Versión actual</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Desarrollador</p>
            <p className="additional__description">Casino</p>
          </div>
        </div>
      </div>
      <div className="additional__wrapper additional__wrapper--desktop">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Actualizado</p>
            <p className="additional__description">23.01.25</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Talla</p>
            <p className="additional__description">4.7MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Descargas</p>
            <p className="additional__description">77421</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">Versión actual</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Edad</p>
            <p className="additional__description">18+</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">Desarrollador</p>
            <p className="additional__description">Casino</p>
          </div>
        </div>
      </div>
    </div>
  );
};
